import { render, staticRenderFns } from "./FrieghtQuotMarginPrice.vue?vue&type=template&id=0beddbdb&scoped=true&"
import script from "./FrieghtQuotMarginPrice.vue?vue&type=script&lang=js&"
export * from "./FrieghtQuotMarginPrice.vue?vue&type=script&lang=js&"
import style0 from "./FrieghtQuotMarginPrice.vue?vue&type=style&index=0&id=0beddbdb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0beddbdb",
  null
  
)

export default component.exports